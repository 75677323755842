import React from "react";
import Div from "../Div";

export default function Newsletter({ title, subtitle, placeholder }) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <a
          href="http://eepurl.com/isxpAs"
          target="_blank"
          rel="noreferrer"
          className="cs-subscribe"
        >
          <span>Click Here</span>
        </a>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  );
}
