import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
// import Pagination from "../Pagination";
import PostStyle2 from "../Post/PostStyle2";
import Div from "../Div";
// import Sidebar from "../Sidebar.jsx";
import Spacing from "../Spacing";

export const postData = [
  {
    url: "the-importaince-of-maintaining-a-clean-car",
    src: "/images/blog/beyond-esthetics-post.jpg",
    alt: "Post",
    date: "07 Mar 2023",
    title: "The Importance of Maintaining a Clean Car: Beyond Aesthetics",
    href: "/blog/the-importaince-of-maintaining-a-clean-car",
    subtitle:
      "A car is more than just a means of transportation; it's an extension of our lifestyle and personality. While many car owners prioritize regular maintenance for performance and safety reasons, the cleanliness of the vehicle often takes a back seat. However, maintaining a clean car goes beyond aesthetics and plays a crucial role in preserving its value, ensuring safety, and promoting a pleasant driving experience. In this article, we will delve into the reasons why you need to prioritize keeping your car clean.",
    textHeader: "Preserving Vehicle Value",
    text: "One of the significant reasons to maintain a clean car is to preserve its value. Regular washing and detailing help protect the paintwork from dirt, dust, bird droppings, and other contaminants that can corrode the exterior surface over time. A well-maintained exterior can prevent rust formation, scratches, and paint deterioration, which can significantly impact the resale value of your car.",
    textHeader2: "Protection Against Environmental Damage",
    text2:
      "Our cars are constantly exposed to various environmental elements such as sunlight, rain, snow, and pollutants. Failing to clean your vehicle regularly allows these contaminants to accumulate, leading to potential damage. For instance, prolonged exposure to sunlight can fade the car's paint and cause the dashboard and interior materials to crack. Acidic bird droppings and tree sap can also corrode the paint if left untreated. By maintaining a clean car, you create a protective barrier against these harmful elements, extending the lifespan of your vehicle.",
    textHeader3: "Safety on the Road",
    text3:
      "A clean car contributes to a safer driving experience. The accumulation of dirt, grime, and debris on the windshield, windows, and mirrors obstructs the driver's visibility, especially during nighttime or adverse weather conditions. Regularly cleaning these surfaces ensures optimal visibility, reducing the risk of accidents.Furthermore, neglecting the cleanliness of your car's headlights can result in reduced brightness, making it difficult for other drivers to see you on the road. By keeping them clean and clear, you enhance your visibility and overall safety.",
    textHeader4: "Preventing Health Issues",
    text4:
      "An unclean car can be a breeding ground for bacteria, allergens, and other harmful microorganisms. Dust, pollen, pet dander, and food particles trapped inside the vehicle can trigger allergies and respiratory problems, affecting both the driver and passengers. Regular vacuuming, wiping down surfaces, and disinfecting the interior can help maintain a healthy environment inside the car.",
    textHeader5: "Enhanced Driving Experience",
    text5:
      "Driving a clean car can significantly improve your overall driving experience. A clutter-free and well-maintained interior provides a more comfortable and enjoyable environment. A clean car creates a positive ambiance, reduces stress, and allows you to focus on the road without distractions. Moreover, a clean car creates a positive impression on passengers and can boost your confidence as a car owner. Whether it's your family, friends, or colleagues, a well-maintained vehicle reflects your attention to detail and personal hygiene.",
    text6:
      "Maintaining a clean car goes beyond mere aesthetics. It is essential for preserving the value of your vehicle, protecting it against environmental damage, ensuring safety on the road, preventing health issues, and enhancing the overall driving experience. By incorporating regular cleaning and detailing into your car care routine, you can enjoy the benefits of a clean, comfortable, and safe vehicle while maximizing its longevity and value. Remember, a clean car is a reflection of your personality and attention to detail as a responsible car owner.",
  },
  {
    url: "the-advantages-of-ceramic-coating-for-your-car",
    src: "/images/blog/ceramic-coating-post.jpg",
    alt: "Post",
    date: "10 Feb 2023",
    title:
      "The Advantages of Ceramic Coating for Your Car: Unveiling the Power of Protection",
    href: "/blog/the-advantages-of-ceramic-coating-for-your-car",
    subtitle:
      "When it comes to protecting your car's paintwork and maintaining its showroom shine, traditional waxing and polishing may no longer be the most effective solutions. Enter ceramic coating, a revolutionary protective layer that has gained immense popularity among car enthusiasts and professionals alike. In this article, we will delve into the advantages of ceramic coating, uncovering the power of this advanced technology in preserving and enhancing the appearance of your beloved vehicle.",
    textHeader: "Unmatched Long-Term Protection",
    text: "Ceramic coating offers an unrivaled level of protection for your car's paintwork. Unlike traditional wax or sealants that provide temporary barriers, ceramic coatings form a semi-permanent bond with the paint surface, creating a durable shield against environmental contaminants, UV rays, acid rain, and oxidation. This robust protective layer helps prevent scratches, swirl marks, and fading, ensuring that your car maintains its pristine condition for an extended period.",
    textHeader2: "Exceptional Gloss and Depth",
    text2:
      "One of the most striking benefits of ceramic coating is its ability to enhance the visual appeal of your car. The nanotechnology used in ceramic coatings creates a smooth and glossy surface that amplifies the depth and richness of the paint color. The result is a head-turning finish that makes your car stand out on the road and gives it that 'new car' appearance even years down the line.",
    textHeader3: "Easy Maintenance and Cleaning",
    text3:
      "Ceramic coatings possess hydrophobic properties, meaning they repel water and other liquids. This characteristic makes maintenance and cleaning a breeze. The slick surface prevents dirt, dust, and grime from adhering to the paint, making it easier to wash off with minimal effort. Furthermore, the hydrophobic nature of ceramic coatings reduces the likelihood of water spots and stains, ensuring that your car always looks its best.",
    textHeader4: "Chemical Resistance",
    text4:
      "Exposure to harsh chemicals and contaminants can wreak havoc on your car's paintwork. Ceramic coatings provide excellent chemical resistance, acting as a protective barrier against acidic substances, bird droppings, tree sap, and other corrosive materials. This resistance prevents these substances from etching or staining the paint, helping to maintain the integrity of the surface for an extended period.",
    textHeader5: "Cost-Effective in the Long Run",
    text5:
      "While the initial cost of ceramic coating may be higher compared to traditional methods, it proves to be a cost-effective investment in the long run. The long-lasting protection provided by ceramic coatings reduces the need for frequent waxing or polishing, saving you time and money on maintenance products. Additionally, the preserved condition of your car's paintwork can increase its resale value, further adding to the overall cost-effectiveness of ceramic coating.",
    text6:
      "Ceramic coating has revolutionized the way we protect and maintain our car's paintwork. With its long-lasting durability, exceptional gloss, easy maintenance, chemical resistance, and cost-effectiveness, ceramic coating proves to be a game-changer in the automotive industry. By opting for this advanced protective layer, you can enjoy peace of mind knowing that your car is shielded from the elements while exuding a stunning, showroom-quality finish. Embrace the power of ceramic coating and elevate the appearance and longevity of your prized possession.",
  },
  {
    url: "the-hidden-benefits-of-a-clean-car",
    src: "/images/blog/hidden-benefits-post.jpg",
    alt: "Post",
    date: "05 Mar 2023",
    title:
      "The Hidden Benefits of a Clean Car: Why It Matters More Than You Think",
    href: "/blog/the-hidden-benefits-of-a-clean-car",
    subtitle:
      "Keeping your car clean goes beyond simply maintaining its appearance. While a clean car is undoubtedly pleasing to the eye, it also offers numerous hidden benefits that can positively impact your driving experience, health, and even the environment. In this article, we will explore some of the lesser-known advantages of maintaining a clean car and why it matters more than you might think.",
    textHeader: "Improved Fuel Efficiency",
    text: "Believe it or not, a clean car can contribute to improved fuel efficiency. When your car's exterior is free from dirt, dust, and debris, it experiences less drag while moving through the air. The reduction in drag can result in improved aerodynamics, leading to better fuel economy. Regularly washing your car and keeping its surfaces clean can help optimize its performance and save you money at the gas pump.",
    textHeader2: "Protection against Corrosion",
    text2:
      "Corrosion is the enemy of any vehicle, as it can lead to costly repairs and decreased longevity. A clean car helps protect against corrosion by removing harmful substances that can cause rust to develop on the body and undercarriage. Salt, mud, and road grime are particularly notorious for accelerating corrosion, especially in areas with harsh winters or coastal regions. By keeping your car clean, you create a barrier against these corrosive agents and extend the lifespan of your vehicle.",
    textHeader3: "Enhanced Mental Well-being",
    text3:
      "Your car is an environment where you spend a significant amount of time, whether it's during your daily commute or long road trips. A cluttered and dirty interior can negatively impact your mental well-being and contribute to feelings of stress and discomfort. On the other hand, a clean and organized car interior promotes a sense of calm and order, creating a more pleasant driving experience. Take the time to declutter, vacuum, and wipe down surfaces regularly to create a serene and inviting space.",
    textHeader4: "Positive First Impressions",
    text4:
      "Your car often acts as an extension of yourself and may leave a lasting impression on others. Whether it's a date, a business meeting, or simply giving someone a ride, a clean car reflects professionalism, attention to detail, and pride of ownership. By maintaining a clean car, you present yourself in a positive light and create a favorable impression on passengers and observers alike.",
    textHeader5: "Environmental Responsibility",
    text5:
      "Caring for your car's cleanliness is not only beneficial for you but also for the environment. When you wash your car, it's crucial to do so in an environmentally friendly manner by using biodegradable soaps and conserving water. Additionally, keeping your car clean helps prevent pollutants such as oil, fuel, and chemicals from accumulating and entering storm drains, which can harm local ecosystems. By taking responsibility for the cleanliness of your car, you contribute to a healthier and cleaner environment.",
    text6:
      "Maintaining a clean car goes beyond surface-level aesthetics. It offers numerous hidden benefits that impact your fuel efficiency, protection against corrosion, mental well-being, first impressions, and environmental responsibility. By making cleanliness a priority in your car care routine, you can enjoy a more efficient, enjoyable, and sustainable driving experience. Remember, a clean car not only reflects your personal style but also demonstrates your commitment to responsible car ownership and the world around you.",
  },
  {
    url: "the-art-of-interior-detailing",
    src: "/images/blog/interior-detailing-post.jpg",
    alt: "Post",
    date: "07 Jun 2023",
    title: "The Art of Interior Detailing: Elevating Your Driving Experience",
    href: "/blog/the-art-of-interior-detailing",
    subtitle:
      "While many car owners focus on keeping the exterior of their vehicles clean and polished, the interior often receives less attention. However, a meticulously detailed interior can significantly enhance your driving experience and provide a comfortable and inviting space. In this article, we will explore the art of interior detailing and the numerous benefits it brings to both you and your car.",
    textHeader: "A Clean and Healthy Environment",
    text: "Regular interior detailing ensures a clean and hygienic environment inside your car. Dust, dirt, and allergens can accumulate on surfaces and in the air vents, leading to potential health issues and allergies. Thorough vacuuming, dusting, and sanitizing of all interior components, including seats, carpets, dashboard, and upholstery, help remove these contaminants, creating a healthier space for you and your passengers.",
    textHeader2: "Preservation of Interior Surfaces",
    text2:
      "Interior detailing involves more than just cleaning; it also focuses on preserving and protecting the various surfaces within your car. Conditioning and treating leather seats help prevent cracking and fading, while using appropriate cleaners and protectants on plastic, vinyl, and fabric surfaces helps maintain their appearance and prevent premature wear and tear. By regularly detailing your car's interior, you extend the lifespan of these surfaces, keeping them looking fresh and enhancing their longevity.",
    textHeader3: "Improved Comfort and Aesthetics",
    text3:
      "A well-detailed interior provides a more comfortable and enjoyable driving experience. Deep cleaning of seats and carpets removes stains, odors, and embedded dirt, creating a fresh and inviting atmosphere. Attention to detail in restoring the appearance of the dashboard, door panels, and center console contributes to an aesthetically pleasing interior that you can take pride in. Additionally, detailing can involve the use of air fresheners or odor eliminators to ensure a pleasant scent inside the car.",
    textHeader4: "Enhanced Resale Value",
    text4:
      "Investing in interior detailing can pay off when it comes time to sell your car. A well-maintained and detailed interior significantly increases the resale value of your vehicle. Prospective buyers are more likely to be impressed by a car that exudes cleanliness, freshness, and attention to detail. A detailed interior reflects the care you have put into your vehicle, making it more appealing and potentially commanding a higher price in the market.",
    textHeader5: "Pride of Ownership",
    text5:
      "Taking the time to detail your car's interior demonstrates your pride of ownership and attention to detail as a car enthusiast. A clean and well-kept interior reflects your personality and leaves a positive impression on passengers and observers alike. Driving a car with a meticulously detailed interior allows you to feel a sense of pride and satisfaction in your vehicle.",
    text6:
      "Interior detailing is an art that goes beyond mere cleaning. It creates a clean and healthy environment, preserves the condition of interior surfaces, improves comfort and aesthetics, enhances the resale value of your car, and instills a sense of pride in ownership. By investing in regular interior detailing, you create a pleasant and inviting space for yourself and your passengers, while also maintaining the overall value and appeal of your vehicle. Embrace the art of interior detailing and elevate your driving experience to new heights.",
  },
  {
    url: "maintaining-your-ceramic-coating",
    src: "/images/blog/maintaining-ceramic.jpg",
    alt: "Post",
    date: "20 Jun 2023",
    title: "General guide to help you keep your ceramic coating in excellent condition",
    href: "/blog/maintaining-your-ceramic-coating",
    subtitle:
      "Wash your vehicle regularly to remove dirt, dust, and other contaminants that can accumulate on the ceramic coating. Use a pH-neutral car shampoo and a soft microfiber wash mitt or microfiber to avoid scratching the coating. Remember to always wash the wheels first, then wash the vehicle from top to bottom. Remember to always use a straight line or back and forth motion when using a wash mitt or microfiber cloth.",
    textHeader: "Regular Washing",
    text: "Wash your vehicle regularly to remove dirt, dust, and other contaminants that can accumulate on the ceramic coating. Use a pH-neutral car shampoo and a soft microfiber wash mitt or microfiber to avoid scratching the coating. Remember to always wash the wheels first, then wash the vehicle from top to bottom. Remember to always use a straight line or back and forth motion when using a wash mitt or microfiber cloth.",
    textHeader2: "Use the Two-Bucket Method",
    text2:
      "When washing, use the two-bucket method. Fill one bucket with clean water and another with the car shampoo solution. Dip the mitt or microfiber into the soapy water, wash a section of the vehicle, then rinse in the clean water bucket to remove dirt before reloading it with shampoo. This helps prevent dirt from being transferred back onto the vehicle's surface.",
    textHeader3: "Avoid Harsh Chemicals",
    text3:
      "Avoid using harsh chemicals or abrasive cleaners on your vehicle. They can degrade the ceramic coating and diminish its protective properties. Stick to pH-neutral products designed for automotive use.",
    textHeader4: "Dry Properly",
    text4:
      "After washing, dry your vehicle thoroughly using a clean, soft microfiber towel or a leaf blower. Water droplets can leave water spots on the coating if left to air dry. Avoid wiping the surface with a rough towel or allowing it to air dry.",
    textHeader5: "Apply a Ceramic Coating Booster",
    text5:
      "Periodically, you can enhance the durability and hydrophobic properties of the ceramic coating by applying a ceramic coating booster or a specifically formulated spray sealant. This will rejuvenate the coating and help maintain its protective qualities. We suggest this should be done once every 3-4 months.",
    textHeader6: "Remove Contaminants",
    text6:
      "If you notice any tar, bird droppings, or other stubborn contaminants on the coating, promptly remove them using a dedicated automotive tar remover or a gentle clay bar. Follow the product instructions carefully and avoid excessive rubbing to prevent damaging the coating.",
    textHeader7: "Avoid Automatic Car Washes",
    text7:
      "Automated car washes with brushes can be too harsh on ceramic coatings. It's best to hand wash your vehicle or use touchless car washes that utilize high-pressure water jets and gentle detergents.",
    textHeader8: "Avoid Scratches and Swirl Marks",
    text8:
      "Be cautious to prevent scratches and swirl marks on the ceramic coating. Use proper washing techniques, such as a grit guard in your wash bucket, and avoid wiping the surface with dirty towels or applying excessive pressure.",
    text9:
      "Periodically inspect the condition of your ceramic coating. Look for any areas that may have diminished in hydrophobicity or show signs of wear. If necessary, you can consider adding an additional layer of ceramic coating or call us if you need any assistance. We can book you in for an appointment.",
  },
];
export default function BlogPage() {
  pageTitle("Blog");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Blog"
        bgSrc="/images/pics/street-stylin-car-detailing-logo-wallpaper.jpg"
        pageLinkText="Blog"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            {postData.map((item, index) => (
              <Div key={index}>
                <PostStyle2
                  src={item.src}
                  title={item.title}
                  subtitle={item.subtitle}
                  date={item.date}
                  category={item.category}
                  categoryHref={item.categoryHref}
                  href={item.href}
                />
                {postData.length > index + 1 && <Spacing lg="95" md="60" />}
              </Div>
            ))}
            <Spacing lg="60" md="40" />
            {/* <Pagination /> */}
          </Div>
          {/* <Div className="col-xl-3 col-lg-4 offset-xl-1">
            <Spacing lg="0" md="80" />
            <Sidebar />
          </Div> */}
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Experience the Difference of <br/>Mobile Detailing Excellence"
          btnText="Pick your Package"
          btnLink="/service"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
