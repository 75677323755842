import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
// import Card from "../Card";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import PricingTableList from "../PricingTable/PricingTableList";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import TestimonialSlider from "../Slider/TestimonialSlider";
import Spacing from "../Spacing";

export default function ServicesPage() {
  pageTitle("Service");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Services Offered"
        bgSrc="/images/pics/street-stylin-car-detailing-logo-wallpaper.jpg"
        pageLinkText="Services"
      />
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="UI/UX design"
                    link="/service/ui-ux-design"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="React.js Development"
                    link="/service/reactjs-development"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Marketing"
                    link="/service/digital-marketing"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Technology"
                    link="/service/technology"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Consultancy"
                    link="/service/consultancy"
                    src="/images/service_5.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Creative Design"
                    link="/service/creative-design"
                    src="/images/service_6.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      <Spacing lg="150" md="80" />
      <Div className="container" id="packages">
        <SectionHeading
          title="Providing best <br/>pricing for clients"
          subtitle="Pricing & Packaging"
        />
        <Spacing lg="40" md="40" />
        <h6>If you're reserving our diamond or platinum package, we kindly request you to email us at <a href="mailto:streetstylinmobile@gmail.com">streetstylinmobile@gmail.com</a> with a photo of your vehicle attached. This will ensure that we provide you with the best service possible. We eagerly await the opportunity to assist you.</h6>
        <Spacing lg="40" md="40" />
        <PricingTableList />
      </Div>
      <Spacing lg="125" md="55" />
      <TestimonialSlider />
      {/* <Spacing lg="150" md="80" /> */}
      <Div className="">
        <Cta
          title="Experience the Difference of <br/>Mobile Detailing Excellence"
          // btnText="Pick your Package"
          btnLink="/service"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
