import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "./scss/index.scss";
import { StreetStylinProvider } from "./context/StreetStylinContext";
import { createBrowserHistory } from "history";
import "./i18n";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <StreetStylinProvider>
      <BrowserRouter history={history}>
        <App />
      </BrowserRouter>
    </StreetStylinProvider>
  </React.StrictMode>
);
