import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";
// import DropDown from "./DropDown";
import { useStreetStylin } from "../../context/StreetStylinContext";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../i18n";

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { i18n, t } = useTranslation();
  const {
    state: { menuToggle },
    dispatch,
  } = useStreetStylin();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);
  const handleMenuToogle = (value) => {
    dispatch({ type: "menu-toggle", payload: value });
  };
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img
                    src="/images/svg/street-stylin-logo-horizontal.svg"
                    alt="Logo"
                  />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className={`cs-nav_list ${
                      isSticky ? "cs-sticky_header_active" : ""
                    }`}
                    style={{ display: `${menuToggle ? "block" : "none"}` }}
                  >
                    <img
                      className="cs-nav_list_bg"
                      src="/images/pics/mobile-bg.jpg"
                      alt="mobile-bg"
                    />
                    <li className="menu-item-has-children">
                      <NavLink to="/" onClick={() => handleMenuToogle(false)}>
                        Home
                      </NavLink>
                      {/* <DropDown>
                        <ul>
                          <li>
                            <Link to="/" onClick={() => handleMenuToogle(false)}>
                              Main Home
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="photography-agency"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Photography Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="creative-portfolio"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Creative Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="digital-agency"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Digital Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="marketing-agency"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Marketing Agency
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="showcase-portfolio"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Showcase Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="case-study-showcase"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Case Study Showcase
                            </Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    </li>
                    <li>
                      <NavLink
                        to="about"
                        onClick={() => handleMenuToogle(false)}
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="menu-item-has-children">
                      <NavLink
                        to="service"
                        onClick={() => handleMenuToogle(false)}
                      >
                        Services
                      </NavLink>
                      {/* <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="service"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Services
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/service/service-details"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Service Details
                            </Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    </li>
                    {/* <li className="menu-item-has-children">
                      <NavLink
                        to="portfolio"
                        onClick={() => handleMenuToogle(false)}
                      >
                        Recent Details
                      </NavLink> */}
                    {/* <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="portfolio"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Portfolio
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="portfolio/portfolio-details"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Portfolio Details
                            </Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    {/* </li> */}
                    <li className="menu-item-has-children">
                      <NavLink
                        to="blog"
                        onClick={() => handleMenuToogle(false)}
                      >
                        Blog
                      </NavLink>
                      {/* <DropDown>
                        <ul>
                          <li>
                            <Link
                              to="blog"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Blog
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="blog/blog-details"
                              onClick={() => handleMenuToogle(false)}
                            >
                              Blog Details
                            </Link>
                          </li>
                        </ul>
                      </DropDown> */}
                    </li>
                    <li className="menu-item-has-children">
                      <span
                        className="language-btn"
                        onClick={() =>
                          handleLanguageChange(
                            i18n.language === "en" ? "fr" : "en"
                          )
                        }
                      >
                        {i18n.language === "en" ? "fr" : "en"}
                      </span>
                    </li>
                    <li className="menu-item-has-bottom">
                      <Div className="cs-side_header_box">
                        <ContactInfoWidget title="Contact Us" withIcon />
                      </Div>
                      <Div className="cs-side_header_box">
                        <Newsletter
                          title="Subscribe to our newsletter"
                          subtitle="Copyright © 2023 Street Stylin Mobile Car Detailing All Rights
              Reserved"
                        />
                      </Div>
                      <Div className="cs-side_header_box">
                        <SocialWidget />
                      </Div>
                    </li>
                  </ul>
                  {/* <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => handleMenuToogle(menuToggle ? false : true)}
                  >
                    <span></span>
                  </span> */}
                  <span
                    className="cs-munu_toggle"
                    onClick={() => handleMenuToogle(menuToggle ? false : true)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <span
                  className="language-btn-mobile"
                  onClick={() =>
                    handleLanguageChange(i18n.language === "en" ? "fr" : "en")
                  }
                >
                  {i18n.language === "en" ? "fr" : "en"}
                </span>
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => handleMenuToogle(menuToggle ? false : true)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div className={menuToggle ? "cs-side_header active" : "cs-side_header"}>
        <button
          className="cs-close"
          onClick={() => handleMenuToogle(menuToggle ? false : true)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => handleMenuToogle(menuToggle ? false : true)}
        />
        <Div className="cs-side_header_in">
          <Div className="cs-side_header_shape" />
          <Link className="cs-site_branding" to="/">
            <img
              src="/images/svg/street-stylin-logo-horizontal.svg"
              alt="Logo"
            />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              For more information <br /> Connect with us.
            </h2>
          </Div>
          <Div className="cs-side_header_box">
            <ContactInfoWidget title="Contact Us" withIcon />
          </Div>
          <Div className="cs-side_header_box">
            <Newsletter
              title="Subscribe to our newsletter"
              subtitle="Copyright © 2023 Street Stylin Mobile Car Detailing All Rights
              Reserved"
            />
          </Div>
          <Div className="cs-side_header_box">
            <SocialWidget />
          </Div>
        </Div>
      </Div>
    </>
  );
}
