import React from "react";
import { useState } from "react";
import PricingTable from ".";
import Section from "../Div";
// import Spacing from "../Spacing";
import Slider from "react-slick";

export const packages = [
  {
    title: "BRONZE",
    price: "100",
    currency: "$",
    timeline: "regular",
    features: [
      "Wheel/Tire Clean",
      "Pre Wash",
      "Bug and Grime Removal",
      "Contact Wash",
      "UV Protect Plastics and Trim",
      "Armor All Tires",
    ],
  },
  {
    title: "SILVER",
    price: "100",
    currency: "$",
    timeline: "regular",
    features: [
      "Interior Vacuum",
      "Steam Clean",
      "All Purpose Clean Interior",
      "Extract Carpets",
      "Carpets and Upholstery Shampoo",
      "UV Protect Plastics",
    ],
  },
  {
    title: "GOLD",
    price: "140",
    currency: "$",
    timeline: "regular",
    features: [
      "Wheel/Tire Clean",
      "Pre Wash",
      "Bug and Grime Removal",
      "Contact Wash",
      "UV Protect Plastics and Trim",
      "Engine Detail",
    ],
  },
  {
    title: "ELITE PRO",
    price: "300",
    currency: "$",
    timeline: "regular",
    features: [
      "Pre Wash",
      "Bug and Grime Removal",
      "Contact Wash",
      "UV Protect Plastics and Trim",
      "Light Polish and *Compound if needed.",
      "Wax Scellant",
    ],
  },
  {
    title: "DIAMOND",
    price: "600",
    currency: "$",
    timeline: "regular",
    features: [
      "Pre Wash",
      "Bug and Grime Removal",
      "Contact Wash",
      "UV Protect Plastics and Trim",
      "Light Polish",
      "2 Year Ceramic Coating",
    ],
  },
  {
    title: "PLATINUM",
    price: "900",
    currency: "$",
    timeline: "regular",
    features: [
      "Pre Wash",
      "Bug and Grime Removal",
      "Contact Wash",
      "UV Protect Plastics and Trim",
      "Polish and *Compound if needed.",
      "7 Year Ceramic Coating",
    ],
  },
];

export default function PricingTableList() {
  const [tab, setTab] = useState("regular");
  /** Slider Settings **/
  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: false,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Section className="position-relative">
      <ul className="cs-tab_links cs-style1 cs-mp0 cs-primary_font">
        <li
          className={tab === "regular" ? "active" : ""}
          onClick={() => setTab("regular")}
        >
          Regular
        </li>
        <li
          className={tab === "loyalty" ? "active" : ""}
          // onClick={() => setTab("yearly")}
        >
          Loyalty
        </li>
      </ul>
      {tab === "regular" && (
        <Slider {...settings} className="cs-gap-12 cs-arrow_style4">
          {packages.map((item, index) => (
            <PricingTable
              key={index}
              title={item.title}
              price={item.price}
              currency="$"
              timeline="dollars"
              features={item.features}
              btnText="Purchase Now"
              btnLink="https://squareup.com/appointments/book/qbtgldoeb8f818/LXCQW9CATF21W/start"
            />
          ))}
        </Slider>
      )}
      {/* <Section className="row">
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Standard"
              price="29"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Standard"
              price="59"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Professional"
              price="199"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Professional"
              price="399"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
        <Section className="col-lg-4">
          {tab === "monthly" && (
            <PricingTable
              title="Ultimate"
              price="299"
              currency="$"
              timeline="monthly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          {tab === "yearly" && (
            <PricingTable
              title="Ultimate"
              price="599"
              currency="$"
              timeline="yearly"
              features={[
                "Static responsive website",
                "Video marketing",
                "Keywords research",
                "Facebook campaign",
                "eCommerce solution",
                "Google adword",
              ]}
              btnText="Purchase Now"
              btnLink="/"
            />
          )}
          <Spacing lg="25" md="25" />
        </Section>
      </Section> */}
    </Section>
  );
}
