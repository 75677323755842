import { createContext, useContext, useReducer } from "react";
import { initialState, reducer } from "../helper/reducer";
export const StreetStylinContext = createContext();
export const StreetStylinProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StreetStylinContext.Provider value={{ state, dispatch }}>
      {children}
    </StreetStylinContext.Provider>
  );
};

export const useStreetStylin = () => useContext(StreetStylinContext);
