import React from "react";
import Div from "../Div";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import "./footer.scss";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  // const copyrightLinks = [
  //   {
  //     title: "FAQ",
  //     href: "/faq",
  //   },
  //   {
  //     title: "Privacy Policy",
  //     href: "/",
  //   },
  // ];

  const serviceMenu = [
    {
      title: "Exterior Detail",
      href: "/service",
    },
    {
      title: "Interior Detail",
      href: "/service",
    },
    {
      title: "Paint Correction",
      href: "/service",
    },
    {
      title: "Ceramic Coating",
      href: "/service",
    },
  ];

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/svg/street-stylin-logo-horizontal.svg"
                  logoAlt="Logo"
                  text="With a passion for automobiles and a commitment to perfection, we bring the ultimate convenience of professional car care directly to your doorstep."
                />
                <SocialWidget />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="Services" />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Contact Us" />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">
              Copyright © 2023 Street Stylin Mobile Car Detailing All Rights
              Reserved
            </Div>
          </Div>
          {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div> */}
        </Div>
      </Div>
    </footer>
  );
}
