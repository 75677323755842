// import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
// import Sidebar from "../Sidebar.jsx";
import Spacing from "../Spacing";
import { postData } from "./BlogPage";

export default function BlogDetailsPage() {
  const params = useParams();
  pageTitle("Blog Details");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const foundPost = postData.find((el) => el.url === params.blogDetailsId);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title={foundPost.title}
        bgSrc="/images/pics/street-stylin-car-detailing-logo-wallpaper.jpg"
        pageLinkText={params.blogDetailsId}
      />
      {/* End Page Heading Section */}

      {/* Start Blog Details */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-12">
            {/* Start Details Post Content */}
            <Div className="cs-post cs-style2">
              <Div className="cs-post_thumb cs-radius_15">
                <img
                  src={foundPost.src}
                  alt="Post"
                  className="w-100 cs-radius_15"
                />
              </Div>
              <Div className="cs-post_info">
                <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
                  <span className="cs-posted_by">{foundPost.date}</span>
                  <Link to="/blog" className="cs-post_avatar">
                    {/* {foundPost.category} */}
                  </Link>
                </Div>
                <h5>{foundPost.textHeader}</h5>
                <p>{foundPost.text}</p>
                {/* <blockquote className="cs-primary_font">
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, who expound the actual
                  teachings of the great explorer of the truth, the master.
                  <small>Loren Mulari<
                  /small>
                </blockquote> */}
                <h5>{foundPost.textHeader2}</h5>
                <p>{foundPost.text2}</p>
                {/* <Div className="row">
                  <Div className="col-md-6">
                    <img
                      src="/images/blog_details_img_1.jpeg"
                      alt="Blog Details"
                      className="cs-radius_15 w-100"
                    />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                  <Div className="col-md-6">
                    <img
                      src="/images/blog_details_img_2.jpeg"
                      alt="Blog Details"
                      className="cs-radius_15 w-100"
                    />
                    <Div className="cs-height_45 cs-height_lg_45" />
                  </Div>
                </Div> */}
                <h5>{foundPost.textHeader3}</h5>
                <p>{foundPost.text3}</p>
                <h5>{foundPost.textHeader4}</h5>
                <p>{foundPost.text4}</p>
                <h5>{foundPost.textHeader5}</h5>
                <p>{foundPost.text5}</p>
                <p>{foundPost?.text6}</p>
              </Div>
            </Div>
            {/* End Details Post Content */}

            {/* Start Comment Section */}
            {/* <Spacing lg="30" md="30" />
            <h2 className="cs-font_50 cs-m0">Leave A Reply</h2>
            <Spacing lg="5" md="5" />
            <p className="cs-m0">
              Your email address will not be published. Required fields are
              marked *
            </p>
            <Spacing lg="40" md="30" />
            <form className="row">
              <Div className="col-lg-6">
                <label>Full Name*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
                <Div
                  data-lastpass-icon-root="true"
                  style={{
                    position: "relative !important",
                    height: "0px !important",
                    width: "0px !important",
                    float: "left !important",
                  }}
                />
              </Div>
              <Div className="col-lg-6">
                <label>Email*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Website*</label>
                <input type="text" className="cs-form_field" />
                <Div className="cs-height_20 cs-height_lg_20" />
              </Div>
              <Div className="col-lg-12">
                <label>Write Your Comment*</label>
                <textarea cols={30} rows={7} className="cs-form_field" />
                <Div className="cs-height_25 cs-height_lg_25" />
              </Div>
              <Div className="col-lg-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form> */}
            {/* End Comment Section */}
          </Div>
          {/* <Div className="col-xl-3 col-lg-4 offset-xl-1"> */}
          {/* Start Sidebar */}
          {/* <Spacing lg='0' md='80'/>
            <Sidebar /> */}
          {/* End Sidebar */}
          {/* </Div> */}
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      {/* Start Blog Details */}

      {/* Start CTA Section */}
      <Div className="">
        <Cta
          title="Experience the Difference of <br/>Mobile Detailing Excellence"
          btnText="Pick your Package"
          btnLink="/service"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
