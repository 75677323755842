import React from 'react';

export default function PostList() {
  const postData = [
    {
      url: "/blog/blog-details",
      src: "/images/blog/beyond-esthetics-post.jpg",
      alt: "Post",
      date: "07 Mar 2023",
      title: "The Importance of Maintaining a Clean Car: Beyond Aesthetics",
    },
    {
      url: "/blog/blog-details",
      src: "/images/blog/ceramic-coating-post.jpg",
      alt: "Post",
      date: "10 Feb 2023",
      title: "The Advantages of Ceramic Coating for Your Car: Unveiling the Power of Protection",
    },
    {
      url: "/blog/blog-details",
      src: "/images/blog/hidden-benefits-post.jpg",
      alt: "Post",
      date: "05 Mar 2023",
      title: "The Hidden Benefits of a Clean Car: Why It Matters More Than You Think",
    },
    {
      url: "/blog/blog-details",
      src: "/images/blog/interior-detailing-post.jpg",
      alt: "Post",
      date: "07 Jun 2023",
      title: "The Art of Interior Detailing: Elevating Your Driving Experience",
    },
    {
      url: "/blog/blog-details",
      src: "/images/blog/interior-detailing-post.jpg",
      alt: "Post",
      date: "20 Jun 2023",
      title: "General guide to help you keep your ceramic coating in excellent condition",
    },
  ];
  return (
    <ul className="cs-post_3_list cs-mp0">
      {postData.map((item, index) => (
        <li key={index}>
          <div className="cs-post cs-style3">
            <div className="cs-post_left">
              <div className="cs-posted_by">
                <span className="cs-primary_font">{item.date}</span>
                <span>
                  {item.month} <br />
                  {item.year}
                </span>
              </div>
            </div>
            <div className="cs-post_right">
              <h2 className="cs-post_title">
                <a href={item.postHref}>{item.title}</a>
              </h2>
              <div className="cs-post_subtitle">{item.subtitle}</div>
              <div className="cs-post_meta">
                <div className="cs-post_avatar">
                  <div className="cs-post_avatar_img">
                    <img src={item.authorImg} alt="Avatar" />
                  </div>
                  <div className="cs-post_avatar_info">
                    <h4>{item.authorName}</h4>
                    <p>{item.authorDesignation}</p>
                  </div>
                </div>
                <a href={item.postHref} className="cs-text_btn">
                  <span>Read More</span>
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
