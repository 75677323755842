export const initialState = {
  menuToggle: false,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "menu-toggle":
      return {
        ...state,
        menuToggle: payload,
      };
    default:
      return state;
  }
};