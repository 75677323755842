import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
export default function TestimonialSlider() {
  // eslint-disable-next-line no-unused-vars
  const [nav1, setNav1] = useState();
  // eslint-disable-next-line no-unused-vars
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialText:
        "I recently had my car detailed by Street Stylin, and I couldn't be happier with the results! The team was professional, punctual, and did an outstanding job. My car looks brand new, both inside and out. They paid attention to every detail and went above and beyond to ensure customer satisfaction. I highly recommend their services!",
      avatarName: 'Zack Kastner',
      ratings: '5',
    },
    {
      testimonialText:
        "I was amazed by the quality of service provided by Street Stylin. They arrived at my home on time and were equipped with all the necessary tools and products. The team was meticulous in their work, meticulously cleaning every nook and cranny of my car. The final result was stunning, and I felt like I was driving a new vehicle. Their prices are competitive, and the convenience of having them come to me was a huge plus. I will definitely be using their services again!",
      avatarName: 'Ilgiz Temirbekov',
      ratings: '5',
    },
    {
      testimonialText:
        "I can't say enough good things about Street Stylin. From the moment I booked the appointment to the completion of the service, everything was smooth and hassle-free. The team was highly skilled and knowledgeable, and they took the time to explain the process and answer all my questions. They used high-quality products that left my car looking showroom-ready. I was particularly impressed with how well they cleaned the interior upholstery and restored its original beauty. I will definitely be a repeat customer!",
      avatarName: 'Jiawei Zhang',
      ratings: '5',
    },
    {
      testimonialText:
        "I recently hired Street Stylin to detail my SUV, and I'm thrilled with the outcome. The team was prompt, courteous, and extremely thorough. They paid attention to every little detail, from cleaning the windows to polishing the rims. My car hasn't looked this good since the day I bought it. The convenience of having them come to my office was a game-changer, and their prices were very reasonable. I highly recommend their mobile detailing services.",
      avatarName: 'David Sabbah',
      ratings: '5',
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/pics/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            {/* <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div> */}
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
