import React from "react";
import Slider from "react-slick";
import Div from "../Div";
import Post from "../Post";

export default function PostSlider() {
  const postData = [
    {
      url: "/blog/the-importaince-of-maintaining-a-clean-car",
      src: "/images/blog/beyond-esthetics.jpg",
      alt: "Post",
      date: "07 Mar 2023",
      title: "The Importance of Maintaining a Clean Car: Beyond Aesthetics",
    },
    {
      url: "/blog/the-advantages-of-ceramic-coating-for-your-car",
      src: "/images/blog/ceramic-coating.jpg",
      alt: "Post",
      date: "10 Feb 2023",
      title:
        "The Advantages of Ceramic Coating for Your Car: Unveiling the Power of Protection",
    },
    {
      url: "/blog/the-hidden-benefits-of-a-clean-car",
      src: "/images/blog/hidden-benefits.jpg",
      alt: "Post",
      date: "05 Mar 2023",
      title:
        "The Hidden Benefits of a Clean Car: Why It Matters More Than You Think",
    },
    {
      url: "/blog/the-art-of-interior-detailing",
      src: "/images/blog/interior-detailing.jpg",
      alt: "Post",
      date: "07 Jun 2023",
      title: "The Art of Interior Detailing: Elevating Your Driving Experience",
    },
    {
      url: "/blog/maintaining-your-ceramic-coating",
      src: "/images/blog/maintaining-ceramic-post.jpg",
      alt: "Post",
      date: "20 Jun 2023",
      title: "General guide to help you keep your ceramic coating in excellent condition",
    },
  ];

  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            date={item.date}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
